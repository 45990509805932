.fullpage-bg {
  background: #353d4b;
}

.loader:after {
  box-shadow: none;
}

.loader.w200:after {
  -webkit-animation-name: slide;
  -moz-animation-name: slide;
  -o-animation-name: slide;
  animation-name: slide;
}
